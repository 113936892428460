import { pipe } from '@mobily/ts-belt'

/**
 * Fold array of paths with similar prefixes into a single compressed string
 *
 * @example
 * Paths [
 *   "shared/ui/components/NoContentBlock",
 *   "shared/ui/components/Payment/AuthorizePaymentForm",
 *   "shared/ui/components/Payment/ExternalPaymentOpen",
 *   "shared/ui/components/Payment/GravyPaymentForm",
 *   "shared/ui/components/Payment/NoPaymentForm",
 *   "shared/ui/components/Skeleton",
 *   "routes/tv/root",
 *   "routes/home/root",
 * ] are compressed into string
 *   "{routes/{tv/root,home/root},shared/ui/components/{NoContentBlock,Payment/{AuthorizePaymentForm,ExternalPaymentOpen,GravyPaymentForm,NoPaymentForm},Skeleton}}"
 */
export function fold(paths: string[] | Iterable<string>): string {
  return pipe(
    paths,
    trie, // create full trie
    compress, // compress trie into a patricia trie
    stringify // stringify trie
  )
}

/**
 * Trie node
 */
type Segment = Map<string, Segment> | null

/**
 * Create full trie from array of paths
 *
 * @see https://en.wikipedia.org/wiki/Trie
 */
export function trie(paths: string[] | Iterable<string>): Segment {
  const root: Segment = new Map()
  for (const path of paths) {
    const parts = path.split('/')
    let node = root
    for (const part of parts) {
      if (part) {
        let child = node.get(part)
        if (child == null) {
          node.set(part, (child = new Map()))
        }
        node = child
      }
    }
  }
  return root
}

/**
 * Compress trie into a patricia trie
 *
 * @see https://en.wikipedia.org/wiki/Radix_tree (aka https://en.wikipedia.org/wiki/Patricia_trie)
 */
function compress(node: Segment): Segment {
  if (node != null) {
    for (const [path, child] of node.entries()) {
      if (child != null) {
        const size = child.size
        if (size === 0) {
          node.set(path, null)
        } else if (size === 1) {
          const onlyValue = child.entries().next().value as [string, Segment]
          const [subpath, grandchild] = onlyValue
          node.delete(path)
          node.set(path + '/' + subpath, compress(grandchild))
        } else {
          node.set(path, compress(child))
        }
      }
    }
  }
  return node
}

/**
 * Stringify patricia trie into a compressed string
 * Syntax is similar to bash brace expansion // a{d,c,b}e -> ade ace abe
 *
 * @see https://www.gnu.org/software/bash/manual/html_node/Brace-Expansion.html
 */
function stringify(node: Segment): string {
  if (node != null) {
    const parts = []
    for (const [path, child] of node.entries()) {
      const children = stringify(child)
      parts.push(children ? path + '/' + children : path)
    }
    if (parts.length === 1) {
      return parts[0]
    } else if (parts.length > 1) {
      return '{' + parts.join(',') + '}'
    }
  }
  return ''
}
