import type {
  ApiAnswerSliceDtoRecentlyWatchedChannelDto,
  RecentlyWatchedChannelDto,
} from '@setplex/wbs-api-types'
import * as v from 'valibot'
import { response, slice, type ApiType } from '../../../shared/schemas'

// GET /api/v3/channels/recently-watched response item
export const RecentlyWatchedChannelSchema = v.looseObject({
  contentType: v.nullish(v.literal('TV_CHANNEL'), 'TV_CHANNEL'),
  id: v.number(),
  externalId: v.nullish(v.string(), undefined),
  name: v.string(),
  logoUrl: v.nullish(v.pipe(v.string(), v.url()), undefined),
  epgId: v.nullish(v.string(), undefined),
  channelNumber: v.number(),
  locked: v.nullish(v.boolean(), false),
  liveRewind: v.nullish(v.boolean(), false),
  orderType: v.nullish(v.picklist(['First', 'Default', 'Last']), 'Default'),
  resolution: v.nullish(v.picklist(['HD', 'SD', 'FHD', '_2K', '_4K']), 'HD'),
  categoryIds: v.nullish(v.array(v.number()), []),
  updated: v.nullish(v.pipe(v.string(), v.isoTimestamp()), undefined),
  chatAllowed: v.nullish(v.boolean(), false),
  blockedByAcl: v.nullish(v.boolean(), false),
  favorite: v.nullish(v.boolean(), false),
  free: v.nullish(v.boolean(), false),
  // priceSettings?: Array<ContentPriceSettingsDto>
  // purchaseInfo?: ContentPurchaseInfoDto
}) satisfies ApiType<RecentlyWatchedChannelDto, any>

export const RecentlyWatchedChannelsPageResponseSchema = response(
  slice(RecentlyWatchedChannelSchema)
) satisfies ApiType<ApiAnswerSliceDtoRecentlyWatchedChannelDto, any>
