import { attach, createEffect, createStore } from 'effector'
import { produce } from 'immer'
import type { ApiEffect, Page, PageNew, PageableInfo } from '../index.h'
import { type TimeRange } from '../interfaces/epg'
import type { MediaUrl } from '../interfaces/generic'
import type {
  CategoryWithChannels,
  CategoryWithChannelsApi,
  EpgInfo,
  GenerateTvPlaylistParams,
  GetAllTvChannelsByPageParams,
  GetPurchasedTvChannelsParams,
  GetRecentlyWatchedChannelsParams,
  PlaylistChannel,
  TvCategory,
  TvChannel,
  TvChannelUpdateObj,
  TvChannelUpdated,
  TvChannelWithEpg,
  TvEpg,
  TvEpgApiDto,
  TvPlaylist,
} from '../interfaces/tv'

//*
//* EFFECTS
//*

// GET /v3/channels
export const getAllTvChannelsByPageFx: ApiEffect<
  GetAllTvChannelsByPageParams,
  Page<TvChannel>
> = createEffect()

// GET /v3/channels?only-favorites=true
export const getOnlyFavoritesTvChannelsRowFx = attach({
  effect: getAllTvChannelsByPageFx,
  mapParams: () => ({ 'page': 0, 'only-favorites': true }),
})

export const getOnlyFavoritesTvChannelsGridFx = attach({
  effect: getAllTvChannelsByPageFx,
  mapParams: (params?: GetAllTvChannelsByPageParams) => ({
    ...params,
    'page': params?.page || 0,
    'only-favorites': true,
  }),
})

// GET /v3/library/channels/tv
export const getPurchasedTvChannelsFx: ApiEffect<
  GetPurchasedTvChannelsParams,
  Page<TvChannel>
> = createEffect()

export const getPurchasedTvChannelsRowFx = attach({
  effect: getPurchasedTvChannelsFx,
  mapParams: (params?: GetPurchasedTvChannelsParams) => ({
    ...params,
    page: params?.page || 0,
  }),
})

export const getPurchasedTvChannelsGridFx = attach({
  effect: getPurchasedTvChannelsFx,
  mapParams: (params?: GetPurchasedTvChannelsParams) => ({
    ...params,
    page: params?.page || 0,
  }),
})

// GET /v3/channels/tv
export const getTvChannelsByIdFx: ApiEffect<{ ids: number[] }, TvChannel[]> =
  createEffect()

// GET /v3/channels/tv?categoryId=categoryId
export const getChannelsByCategoryIdFx: ApiEffect<
  {
    page: number
    count?: number
    categoryId: number
  },
  Page<TvChannel>
> = createEffect()

// GET /v3/channels/tv?updatedTimeOnly=true
export const getTvChannelsByIdUpdatedOnlyFx: ApiEffect<
  { categoryId: number },
  TvChannelUpdated[]
> = createEffect()

// GET /v3/channels/tv/{channelId}/url
export const getTvChannelUrlFx: // startTime and endTime is SEC
ApiEffect<
  {
    channelId: number
    startTime?: number
    endTime?: number
    isRewind?: boolean
  },
  MediaUrl
> = createEffect()

// GET /v3/channels/tv/categories
export const getTvCategoriesByPageFx: ApiEffect<
  { page: number; count?: number },
  Page<TvCategory>
> = createEffect()

// PATCH /v3/channels/tv/{channelId}
export const updateTvChannelFx: ApiEffect<TvChannelUpdateObj, void> =
  createEffect()

// imitate GET /categories/{categoryId}
export const getTvCategoryFx: ApiEffect<
  { categoryId: number },
  TvCategory | null
> = createEffect()

// GET /v3/channels/tv?only-recommended=true
export const getRealRecommendedTvChannelsFx: ApiEffect<
  {
    count?: number
  },
  TvChannel[]
> = createEffect()

export const getCategoriesWithChannelsFx: ApiEffect<
  {
    page: number
    count?: number
  },
  Page<CategoryWithChannelsApi>
> = createEffect()

// POST /media/tv/epg
export const getEpgInformationFx: ApiEffect<
  {
    epgIds: string[]
    period: TimeRange
  },
  { epg: TvEpgApiDto; period: TimeRange }
> = createEffect()

// POST /media/tv/epg
export const getEpgInformationFormattedFx: ApiEffect<
  {
    epgIds: string[]
    channels: TvChannel[] | TvChannelWithEpg[]
    period: TimeRange
  },
  { epg: TvEpg; period: TimeRange }
> = createEffect()

//GET /media/tv/epg

export const getProgramEPGInfoFx: ApiEffect<
  {
    epgId: string
    programTitle: string
    start: number
  },
  EpgInfo[]
> = createEffect()

export const getFeaturedChannels: ApiEffect<void, TvChannel[]> = createEffect()

// GET /v3/channels/recently-watched
export const getRecentlyWatchedChannelsFx: ApiEffect<
  GetRecentlyWatchedChannelsParams | undefined | void,
  PageNew<TvChannel>
> = createEffect()

// PUT /v3/channels/recently-watched/{channelId}
export const addRecentlyWatchedChannelFx: ApiEffect<{ id: number }, void> =
  createEffect()

// *
// * STORES
// *

/***************** $tvCategoriesWithChannels *******************/

export const initCategoriesWithChannelsFx = attach({
  effect: getCategoriesWithChannelsFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})

export const loadCategoriesWithChannelsFx = attach({
  effect: getCategoriesWithChannelsFx, // use to paginate over subCategories
})

// to paginate over categories use - loadCategoriesWithChannelsFx
export const $tvCategoriesWithChannels = createStore<CategoryWithChannels[]>([])
  .on(initCategoriesWithChannelsFx.doneData, (_, payload) =>
    payload.content.map((page) => ({
      ...page,
      content: page.content.content,
    }))
  )
  .on(loadCategoriesWithChannelsFx.doneData, (state, payload) => {
    const newContent = payload.content.map((page) => ({
      ...page,
      content: page.content.content,
    }))
    return [...state, ...newContent]
  })

// pagination of main array of categories $tvCategoriesWithChannels
export const $pageInfoCategoriesWithChannels = createStore<PageableInfo | null>(
  null
).on(
  [initCategoriesWithChannelsFx.done, loadCategoriesWithChannelsFx.done],
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

/***************** $tvCategoriesWithChannels *******************/

/***************** $tvPlaylist *******************/

const playlistDefault = { categoryId: null, channels: [], pageInfo: null }

export const generateTvPlaylist: ApiEffect<
  GenerateTvPlaylistParams,
  TvPlaylist
> = createEffect()

// pagination over channels
export const loadMoreTvPlaylistFx: ApiEffect<
  { page: number; categoryId: number },
  Page<PlaylistChannel>
> = createEffect()

export const $tvPlaylist = createStore<TvPlaylist>(playlistDefault)

$tvPlaylist
  .on(generateTvPlaylist.doneData, (_, content) => content)
  .on(loadMoreTvPlaylistFx.doneData, (state, payload) => {
    const { content, ...newPageInfo } = payload
    return {
      categoryId: state?.categoryId,
      channels: [...(state?.channels || []), ...content],
      pageInfo: newPageInfo,
    } as TvPlaylist
  })

/***************** $tvPlaylist *******************/

/**************** $channelsByCategory *******************/

export const loadChannelsFx = attach({ effect: getChannelsByCategoryIdFx }) // for pagination over channels
export const loadTvCategoryFx = attach({ effect: getTvCategoryFx })

export const initChannelsCategoryFx: ApiEffect<
  { categoryId: number },
  CategoryWithChannels | null
> = createEffect()

export const $channelsByCategory = createStore<CategoryWithChannels | null>(
  null
)
  .on(initChannelsCategoryFx.doneData, (_ignored, payload) => payload)
  .on(loadChannelsFx.doneData, (state, { content }) => {
    return produce(state, (draft) => {
      if (draft) {
        draft.content = [...draft.content, ...content]
      }
    })
  })

export const $channelsByCategoryPageInfo = createStore<PageableInfo | null>(
  null
).on(
  loadChannelsFx.done,
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

/**************** $channelsByCategory *******************/

/**************** $realRecommendedTvChannels *******************/
export const loadRealRecommendedTvChannelsFx = attach({
  effect: getRealRecommendedTvChannelsFx,
})
export const $realRecommendedTvChannels = createStore<TvChannel[]>([]).on(
  loadRealRecommendedTvChannelsFx.doneData,
  (_, channelsArr) => channelsArr
)
/**************** $realRecommendedTvChannels *******************/

/**************** $infiniteChannelsCategory *******************/

export const initInfiniteChannelsCategoryFx = attach({
  effect: getAllTvChannelsByPageFx,
  mapParams: (params) => ({ ...params, page: 0 }),
})

const fillFakeCategory = (
  channels: TvChannel[],
  isMoreThanOnePage: boolean
): CategoryWithChannels => ({
  id: 0,
  name: 'All',
  sortOrder: 0,
  parentCategoryId: 0,
  channelIds: [],
  isMoreThanOnePage,
  content: channels,
})

// to paginate over categories use - loadInfiniteChannelsCategoryFx
export const $infiniteChannelsCategory =
  createStore<CategoryWithChannels | null>(null).on(
    initInfiniteChannelsCategoryFx.doneData,
    (_, payload) => fillFakeCategory(payload.content, !payload.last)
  )

/**************** $infiniteChannelsCategory *******************/
