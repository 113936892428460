import type { API, AppearanceRow } from '@setplex/tria-api'
import * as v from 'valibot'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import {
  GenericSliceInfoSchema,
  GenericSuccessResponseSchema,
} from '../../shared/schemas'
import { RowsPageResponseSchema, StyleSetResponseSchema } from './schemas'

// --- old formatters helpers, TODO: refactor this
import { formatContinueWatchingItem } from '../continue-watching/continueWatching.format'
import { formatLiveEvent } from '../live-events/live-events.format'
import { formatPartnerProduct } from '../partnerProgram/partnerProgram.format'
import { formatShowBundle } from '../showBundles/showBundles.format'
import { formatChannel } from '../tv/tv.format'
import { formatTvBundle } from '../tvBundles/tvBundles.format'
import { formatShow } from '../tvshows/tvshows.format'
import { formatVodBundle } from '../vodBundles/vodBundles.format'
import { formatVod } from '../vods/vods.format'
// ---

export function use(
  http: HttpClient,
  appearance: API['appearance'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // GET /v3/appearance
  appearance.getColorSchemeFx.use(async () => {
    const json = await http.get(`/v3/appearance`)
    const response = v.parse(StyleSetResponseSchema, json)
    return response.payload
  })

  // GET /v3/appearance/modules/home/rows
  appearance.getHomeRowsFx.use(
    async (args?: { page?: number; count?: number } | undefined | void) => {
      let params: URLSearchParams | undefined
      if (args) {
        params = new URLSearchParams()
        if (args.page != null) params.set('page', String(args.page))
        if (args.count != null) params.set('count', String(args.count))
      }

      // prettier-ignore
      const json = await http.get(`/v3/appearance/modules/home/rows` + (params ? `?${params}` : ''))
      const response = v.parse(RowsPageResponseSchema, json)
      return response.payload
    }
  )

  // GET resource row
  appearance.getRowFx.use(async (row: AppearanceRow) => {
    let json: unknown

    // fallback to old wbs, if defined
    if (row.resource.fallback) {
      try {
        json = await row.resource.fallback()
      } catch (error) {
        throw new Error(`Row resource fallback failed: ${error}`)
      }
    }

    // fetch row resource from new wbs api, if no fallback defined
    else {
      const searchParams = new URLSearchParams(row.resource.search)

      if (row.paged) {
        searchParams.set('page', '0')
        searchParams.set('count', '36')
      }

      json = await http.get(row.resource.path, { searchParams })
    }

    if (!v.is(GenericSuccessResponseSchema, json)) {
      throw new Error('Invalid row resource response')
    }

    const payload = json.payload
    const isPayloadPaged = v.is(GenericSliceInfoSchema, payload)

    let items: any[]
    let last: boolean

    if (row.paged) {
      if (!isPayloadPaged) {
        throw new Error(
          'Invalid row resource response, page or slice info expected'
        )
      }

      items = payload.content
      last = payload.last
    } else {
      if (isPayloadPaged) {
        throw new Error(
          'Invalid row resource response, page or slice info was unexpected'
        )
      }
      if (!Array.isArray(payload)) {
        throw new Error('Invalid row resource response, array expected')
      }

      items = payload
      last = true
    }

    items = items.map((item) => {
      switch (row.contentType) {
        case 'LIVE_EVENT':
          return formatLiveEvent(item) // v.parse(LiveEventItemSchema, item)
        case 'MIXED':
          return formatContinueWatchingItem(item) // return v.parse(MixedItemSchema, item)
        case 'PARTNER_PRODUCT':
          return formatPartnerProduct(item) // v.parse(PartnerProductItemSchema, item)
        case 'TV_BUNDLE':
          return formatTvBundle(item) // v.parse(ChannelsBundleItemSchema, item)
        case 'TV_CHANNEL':
          return formatChannel(item) // v.parse(ChannelItemSchema, item)
        case 'TV_SHOW_BUNDLE':
          return formatShowBundle(item) // v.parse(TvShowsBundleItemSchema, item)
        case 'TV_SHOW':
          return formatShow(item) // v.parse(TvShowItemSchema, item)
        case 'VOD_BUNDLE':
          return formatVodBundle(item) // v.parse(VodsBundleItemSchema, item)
        case 'VOD':
          return formatVod(item) // v.parse(VodItemSchema, item)
      }
    })

    // can show "see all" button only for partner products and live events for now
    // so reset last flag to `true` (no "see all" button) for other content types
    if (
      !last &&
      row.contentType !== 'PARTNER_PRODUCT' &&
      row.contentType !== 'LIVE_EVENT'
    ) {
      last = true
    }

    return {
      items: items.slice(0, 36), // crop non-paged items to 36
      last,
    }
  })
}
